var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative sm:pb-52"},[_c('div',{staticClass:"flex flex-col md:flex-row -mx-2"},_vm._l((_vm.offers),function(offer){return _c('div',{key:offer.id,staticClass:"px-2 w-full md:w-1/2 mb-2 md:mb-0"},[_c('label',{staticClass:"relative cursor-pointer flex flex-col justify-center transform transition-all  bg-black bg-opacity-50 border-2 border-black border-opacity-0  px-4 py-4 sm:py-8 rounded-lg h-full",class:offer.id === _vm.offerSelected.id &&
            ' bg-white bg-opacity-100 text-black lg:scale-110 z-20'},[_c('span',{staticClass:"absolute left-0 top-0 m-4"},[(offer.id === _vm.offerSelected.id)?_c('inline-svg',{staticClass:"w-5 h-5 mr-3 flex-shrink-0 fill-current text-green-500",attrs:{"src":"/img/icon-check-fill.svg"}}):_c('inline-svg',{staticClass:"w-5 h-5 mr-3 flex-shrink-0 fill-current",attrs:{"src":"/img/icon-check-empty.svg"}})],1),_c('h2',{staticClass:"font-headings text-2xl text-center flex-shrink-0 leading-none",domProps:{"textContent":_vm._s(offer.name)}}),_vm._m(0,true),_c('p',{staticClass:"text-center text-4xl mt-2 sm:mt-12 mb-2 font-headings"},[_vm._v(" "+_vm._s(_vm._f("toEuro")(offer.price))+" "),_c('sup',[_vm._v("*")])]),_c('p',{staticClass:"text-xs text-center mt-2 opacity-60 flex-grow flex-shrink-0",domProps:{"innerHTML":_vm._s(
            _vm.$t(("offers." + (offer.slug) + ".description"), {
              price: offer.price,
            })
          )}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.offerSelected),expression:"offerSelected"}],staticClass:"h-0 w-0 opacity-0 absolute",attrs:{"type":"radio","name":"offer","id":'offer-' + offer.id},domProps:{"value":offer,"checked":_vm._q(_vm.offerSelected,offer)},on:{"change":function($event){_vm.offerSelected=offer}}})])])}),0),_c('div',{staticClass:"text-center mt-6 sm:mt-12"},[_c('button',{staticClass:"btn bg-green-600 hover:bg-green-500 focus:bg-green-600 text-white w-full sm:w-auto",domProps:{"textContent":_vm._s(_vm.$t('button.selectOffer', { offerName: _vm.offerSelected.name }))},on:{"click":_vm.onSubmit}})]),_c('p',{staticClass:"text-xs text-justify opacity-50 mt-4 sm:absolute",domProps:{"textContent":_vm._s(
      '*' +
        _vm.$t(("offers." + (_vm.offerSelected.slug) + ".legalText"), {
          price: _vm.offers[1].price,
        })
    )}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-center"},[_c('span',{staticClass:"h-px bg-red-500 w-9 inline-block"})])}]

export { render, staticRenderFns }