<template>
  <div class="my-auto  mx-auto">
    <container>
      <div class="flex flex-col md:flex-row -mx-6">
        <div class="px-6 w-full md:w-2/3 md:pr-24">
          <transition
            appear
            appear-active-class="appear-from"
            appear-to-class="appear-to"
          >
            <div class=" mb-4 sm:mb-12">
              <h1
                class="  text-center text-3xl lg:text-4xl font-headings"
                v-text="$t('title.offer')"
              />
            </div>
          </transition>
          <offers-list />
        </div>
        <div class="px-6 w-full md:w-1/3 mt-12 md:mt-0">
          <sidebar-summary isEditable />
        </div>
      </div>
      <!-- <h2
        class="text-xl font-headings mb-4 mt-6"
        v-text="$t('title.services')"
      />
      <div class="flex flex-wrap -mx-2">
        <div
          v-for="item in services"
          :key="item.id"
          class="w-1/2 sm:w-1/3 md:w-1/6 px-2 mb-4 transform transition-transform hover:scale-150 hover:z-10"
        >
          <div class="relative">
            <div
              class="relative h-0 pt-48 w-full overflow-hidden block rounded"
            >
              <div class="absolute inset-0 bg-black bg-opacity-20 z-10"></div>
              <picture>
                <source
                  :srcset="
                    `/img/services/service${item.id}.jpg.webp 1x, /img/services/service${item.id}@2x.jpg.webp 2x`
                  "
                  type="image/webp"
                />
                <source
                  :srcset="
                    `/img/services/service${item.id}.jpg 1x, /img/services/service${item.id}@2x.jpg 2x`
                  "
                  type="image/jpeg"
                />
                <img
                  :src="`/img/services/service${item.id}.jpg`"
                  :alt="item.title"
                  loading="lazy"
                  class="absolute inset-0 w-full h-full object-cover"
                />
              </picture>
            </div>
            <p
              v-text="item.title"
              class="text-xs p-2 mt-2 absolute inset-x-0 bottom-0 z-20"
            />
          </div>
        </div>
      </div> -->
    </container>
  </div>
</template>

<script>
import OffersList from "../components/OffersList.vue";

import SidebarSummary from "../components/ZodiacFormComponents/SidebarSummary.vue";
import Container from "../components/Ui/Container";
export default {
  name: "Offer",
  components: { SidebarSummary, OffersList, Container },
  computed: {
    services() {
      return this.$store.state.services;
    },
  },
  metaInfo() {
    return { title: this.$t("title.offer") };
  },
};
</script>
