<template>
  <div class="relative sm:pb-52">
    <div class="flex flex-col md:flex-row -mx-2">
      <div
        class="px-2 w-full md:w-1/2 mb-2 md:mb-0"
        v-for="offer in offers"
        :key="offer.id"
      >
        <label
          class="relative cursor-pointer flex flex-col justify-center transform transition-all  bg-black bg-opacity-50 border-2 border-black border-opacity-0  px-4 py-4 sm:py-8 rounded-lg h-full"
          :class="
            offer.id === offerSelected.id &&
              ' bg-white bg-opacity-100 text-black lg:scale-110 z-20'
          "
        >
          <span class="absolute left-0 top-0 m-4">
            <inline-svg
              v-if="offer.id === offerSelected.id"
              src="/img/icon-check-fill.svg"
              class="w-5 h-5 mr-3 flex-shrink-0 fill-current text-green-500"
            />
            <inline-svg
              v-else
              src="/img/icon-check-empty.svg"
              class="w-5 h-5 mr-3 flex-shrink-0 fill-current"
            />
          </span>
          <h2
            class="font-headings text-2xl text-center flex-shrink-0 leading-none"
            v-text="offer.name"
          />
          <p class="text-center">
            <span class="h-px bg-red-500 w-9 inline-block"></span>
          </p>
          <p class="text-center text-4xl mt-2 sm:mt-12 mb-2 font-headings">
            {{ offer.price | toEuro }} <sup>*</sup>
          </p>
          <p
            class="text-xs text-center mt-2 opacity-60 flex-grow flex-shrink-0"
            v-html="
              $t(`offers.${offer.slug}.description`, {
                price: offer.price,
              })
            "
          />
          <input
            type="radio"
            name="offer"
            :id="'offer-' + offer.id"
            :value="offer"
            v-model="offerSelected"
            class="h-0 w-0 opacity-0 absolute"
          />
        </label>
      </div>
    </div>
    <div class="text-center mt-6 sm:mt-12">
      <button
        class="btn bg-green-600 hover:bg-green-500 focus:bg-green-600 text-white w-full sm:w-auto"
        v-text="$t('button.selectOffer', { offerName: offerSelected.name })"
        @click="onSubmit"
      />
    </div>
    <p
      class="text-xs text-justify opacity-50 mt-4 sm:absolute"
      v-text="
        '*' +
          $t(`offers.${offerSelected.slug}.legalText`, {
            price: offers[1].price,
          })
      "
    />
  </div>
</template>

<script>
import i18n from "@/i18n.js";
export default {
  name: "OffersList",

  data() {
    return {
      offerSelected: null,
    };
  },

  created() {
    this.offerSelected = this.offerSelectedSaved
      ? this.offerSelectedSaved
      : this.offers[0];
  },

  methods: {
    onSubmit() {
      this.$store.commit("updateOfferSelected", this.offerSelected);
      this.$router.push(this.$t("router.payment.path"));
    },
  },

  computed: {
    offers() {
      return this.$store.state.offers;
    },
    offerSelectedSaved() {
      return this.$store.state.offerSelected;
    },
  },

  filters: {
    toEuro(value) {
      return new Intl.NumberFormat(i18n.t("date_format"), {
        style: "currency",
        currency: "EUR",
      }).format(value);
    },
  },
};
</script>
